import React from 'react'

const Image = ({ className, src, alt }) => (
  <div
    className={`${className} group w-full h-full overflow-hidden flex items-center justify-center`}
  >
    <img
      src={src}
      alt={alt}
      className="object-cover object-center w-full h-full visible"
    />
  </div>
)

export default Image
