import { useQuery } from '@apollo/client'
import classNames from 'classnames'
import { H2 } from 'components/Headings'
import Image from 'components/Image/Image'
import { Panel } from 'components/Panel'
import { Slider } from 'components/Slider'
import styles from 'components/Slider/Slider.module.scss'
import { testimonialContentsQuery } from 'lib/queries'
import { useIntl } from 'react-intl'

export const Quote = ({ quote, person, imageUrl, color }) => (
  <figure className="w-5/6 my-8 mx-auto items-center">
    <div
      className={classNames(
        color ? `text-${color}` : 'text-white',
        styles.quoteMark
      )}
    >
      &ldquo;
    </div>
    <blockquote
      className="text-xl text-left"
      dangerouslySetInnerHTML={{ __html: quote }}
    />
    {imageUrl ? (
      <div className="w-32 h-32 inline-block my-6">
        <Image
          src={imageUrl}
          alt={person}
          className="rounded-full border-4 border-white"
          round
        />
      </div>
    ) : (
      <br />
    )}
    <figcaption className="text-lg font-bold">{person}</figcaption>
  </figure>
)

const sliderSettings = {
  universal: {
    breakpoint: { max: 4000, min: 0 },
    items: 1,
  },
}

export const Testimonials = ({
  color,
  hasWave,
  imageUrl,
  hideTitle,
  highlight,
  className,
  category = 'b2b',
}) => {
  const { locale, formatMessage } = useIntl()
  const { loading, error, data } = useQuery(testimonialContentsQuery, {
    variables: { locale, category },
  })

  if (loading) {
    return null
  }

  if (error) {
    console.error(error)
    return null
  }

  return (
    <Panel
      color={color}
      className="bg-cover bg-top"
      style={{
        backgroundImage: imageUrl,
      }}
      hasWave={hasWave}
    >
      <Panel.Container
        className={classNames('text-center mt-32 mb-0', className)}
      >
        {!hideTitle && (
          <H2>{formatMessage({ id: 'header.customerSatisfaction' })}</H2>
        )}
        <Slider
          settings={sliderSettings}
          removeArrowOnDeviceType={['mobile']}
          dotListClass="relative flex mt-8"
          arrows
          swipeable
          draggable
          itemClass="my-auto items-center"
          renderDotsOutside
        >
          {data.testimonialContents.map((item) => (
            <Quote
              color={highlight}
              key={item.id}
              quote={item.body}
              person={item.name}
              imageUrl={item.imageUrl}
            />
          ))}
        </Slider>
      </Panel.Container>
    </Panel>
  )
}
