import { useQuery } from '@apollo/client'
import { H2 } from 'components/Headings'
import Panel from 'components/Panel'
import { Slider } from 'components/Slider'
import { partnerContentsQuery } from 'lib/queries'
import { useIntl } from 'react-intl'

const sliderSettings = {
  xl: {
    breakpoint: { max: 4000, min: 3000 },
    items: 10,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 6,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 4,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
}

const Partner = ({ partner }) => {
  return (
    <div className="m-6">
      <a href={partner.url} target="_blank" rel="noopener noreferrer">
        <img src={partner.imageUrl} alt={partner.name} />
      </a>
    </div>
  )
}

export const Partners = () => {
  const intl = useIntl()
  const { loading, error, data } = useQuery(partnerContentsQuery, {
    variables: { locale: intl.locale },
  })

  if (loading) {
    return null
  }

  if (error) {
    console.error(error)
    return null
  }

  const partnerContents = data.partnerContents.filter(
    (partner) => partner.imageUrl
  )

  return (
    <Panel color="white">
      <H2 className="text-center">
        {intl.formatMessage({ id: 'header.companiesWhoTrust' })}
      </H2>
      <Slider
        settings={sliderSettings}
        itemClass="flex justify-center items-center"
        removeArrowOnDeviceType={['mobile']}
      >
        {partnerContents.map((partner) => (
          <Partner key={partner.id} partner={partner} />
        ))}
      </Slider>
    </Panel>
  )
}
