import { useIntl } from 'react-intl'

const FieldError = ({ error }) => {
  const { formatMessage } = useIntl()

  const text = error.id ? formatMessage({ id: error.id }, error.values) : error

  return <div className="text-red p-1">{text}</div>
}

export default FieldError
