import { Features } from 'components/pages/Home/Features'
import { Form } from 'components/pages/Home/Form'
import { Hero } from 'components/pages/Home/Hero'
import { Stats } from 'components/pages/Home/Stats'
import { Testimonials } from 'components/pages/Home/Testimonials'
import { VideoPanel } from 'components/pages/Home/Video'
import { Partners } from 'components/pages/Pricing/Partners'
import { SEO } from 'components/SEO/SEO'

const Home = () => {
  return (
    <>
      <SEO title="Home" image="images/01.png" />
      <Hero />
      <VideoPanel />
      <div className="w-full border border-lightGray" />
      <Partners />
      <Features color="purple" />
      <Stats />
      <Testimonials color="purple" category="b2b" />
      <Form color="yellow" hasWave imageUrl="/images/05.png" />
    </>
  )
}

export default Home
