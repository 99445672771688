import GroupHome from 'components/pages/Groups/Home'
import Marketplace from 'components/pages/Groups/Marketplace'
import B2BHome from 'components/pages/Home'
import { withApollo } from 'lib/apollo'
import { connectServerSideProps } from 'lib/ssr'

const Home = ({ groupId }) => {
  if (groupId) {
    return groupId === 'marketplace' ? (
      <Marketplace />
    ) : (
      <GroupHome groupId={groupId} />
    )
  }

  return <B2BHome />
}

export const getServerSideProps = connectServerSideProps()

export default withApollo()(Home)
