import classNames from 'classnames'
import { ButtonLink } from 'components/Button'
import Image from 'components/pages/Groups/Image'
import TitleImage from 'components/pages/Groups/TitleImage'
import { useCurrentUser } from 'lib/UserContext'
import Link from 'next/link'
import { useIntl } from 'react-intl'

import styles from './hero.module.scss'

const Hero = ({ group }) => {
  const { formatMessage, locale } = useIntl()
  const { logoImage, pageLogoImage, title, subtitle } = group

  const ProgramTitle = () => (
    <div
      style={{ fontFamily: group?.styles.titleFontName }}
      className={classNames('flex-0 pb-4 font-bold text-2xl', styles.title)}
    >
      {title}
    </div>
  )

  const ProgramDescription = () => (
    <div
      className={classNames(
        'flex-1 mb-8 sm:mb-6 w-full lg:mb-8 text-lg',
        styles.subtitle
      )}
    >
      {subtitle}
    </div>
  )

  const SignUp = () => {
    const { currentUser } = useCurrentUser()
    const userRedirect = `/${locale}/personal`

    const groupButtonStyle = {
      backgroundColor: group.styles.accentColor,
      color: group.styles.accentTextColor,
    }

    return (
      <Link href={currentUser ? userRedirect : `/${locale}/sign-up`} passHref>
        <ButtonLink
          className="flex-0 w-1/2 md:w-2/3 text-sm lg:text-base"
          style={groupButtonStyle}
        >
          {formatMessage({
            id: currentUser ? 'group.dashboard' : 'group.getStarted',
          })}
        </ButtonLink>
      </Link>
    )
  }

  const GroupLogo = ({ group }) =>
    pageLogoImage?.imageUrl || logoImage?.imageUrl ? (
      <div className="pb-6 flex-0 max-h-24">
        <Image
          src={pageLogoImage?.imageUrl || logoImage?.imageUrl}
          alt=""
          className="relative mx-0 sm:mx-auto md:mx-0 h-auto lg:max-h-20 max-h-16"
        />
      </div>
    ) : (
      <h1 className="text-4xl font-black pb-6 flex-0">{group.name}</h1>
    )

  // @TODO: use LogoSelect instead of GroupLogo, for DRYer code
  return (
    <section className="wrapper pt-6 pb-12 sm:pt-12 sm:pb-16">
      <div className="mx-auto container flex flex-col-reverse md:flex-row items-center">
        <div
          id="hero-text"
          className="w-full md:w-1/2 lg:w-2/5 pr-0 md:pr-8 pt-6 md:pt-0 flex flex-col sm:flex-row-reverse md:flex-col items-center"
        >
          <div className="w-full flex-0 sm:w-2/5 md:w-full justify-center text-left sm:text-center md:text-left">
            <GroupLogo group={group} />
          </div>
          <div className="w-full flex-0 sm:w-3/5 sm:pr-8 md:pr-0 md:w-full items-center my-auto">
            <ProgramTitle />
            <ProgramDescription />
            <SignUp />
          </div>
        </div>
        <div
          id="hero-banner"
          className={classNames(
            'relative w-full md:w-1/2 lg:w-3/5 pb-1/2 md:pb-0',
            styles.titleImage
          )}
        >
          <TitleImage group={group} />
        </div>
      </div>
    </section>
  )
}

export default Hero
