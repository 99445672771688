import FieldError from 'components/controls/FieldError'
import Input from 'components/Input/Input'
import { Field as FormikField } from 'formik'
import { useIntl } from 'react-intl'

const Field = ({ name, type = 'text', control, compact, ...inputProps }) => {
  const Control = control || Input
  const intl = useIntl()
  const label = intl.formatMessage({ id: `form.${name}` })

  return (
    <FormikField name={name}>
      {({ field, meta }) => (
        <div className={compact ? 'mb-2' : 'mb-4'}>
          {!compact && <label className="block mb-2 font-black">{label}</label>}
          <Control placeholder={label} type={type} {...field} {...inputProps} />
          {meta.touched && meta.error && <FieldError error={meta.error} />}
        </div>
      )}
    </FormikField>
  )
}

export default Field
