import React from 'react'

import Image from '../Image/Image'

const Card = ({ title = '', image = '', children }) => (
  <div className="overflow-hidden text-center sm:text-left sm:flex md:p-4 pb-8 lg:w-1/2">
    <div className="w-32 h-32 inline-block flex-none">
      <Image src={image} alt="image" className="rounded-full" />
    </div>
    <div className="sm:px-6 py-8 md:py-4">
      <div className="font-black text-xl mb-2">{title}</div>
      <div className="text-gray-700 text-base">{children}</div>
    </div>
  </div>
)

export default Card
