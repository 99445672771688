import RequestDemoForm from 'components/Forms/RequestDemo/RequestDemoForm'
import { H2 } from 'components/Headings'
import Query from 'components/PageContent/Query'
import { Panel } from 'components/Panel'

export const Form = ({ imageUrl, ...props }) => {
  return (
    <Query id="b2b.home.form">
      {({ pageContent }) => (
        <Panel className="pt-16" {...props}>
          <div className="container mx-auto md:flex justify-between z-10 bg-bottom mt-6 md:mt-0">
            <div className="md:w-5/12">
              <H2>{pageContent.title}</H2>
              <div
                className="mb-10"
                dangerouslySetInnerHTML={{ __html: pageContent.body }}
              />
            </div>
            <div className="md:w-5/12">
              <RequestDemoForm id="request-demo" />
            </div>
          </div>
          {imageUrl && (
            <div className="nowrap md:-mt-32 md:-mb-32 lg:-mt-64 z-0 relative">
              <img src={imageUrl} className="w-full" alt="" />
            </div>
          )}
        </Panel>
      )}
    </Query>
  )
}
