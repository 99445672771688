import Button from 'components/Button/Button'
import Select from 'components/Select/Select'
import { Formik } from 'formik'
import Cookies from 'js-cookie'
import Router from 'next/router'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'

import Field from '../Field'
import { initialValues, validationSchema } from './validation'

const RequestDemoForm = ({ id, onSubmit }) => {
  const { locale, formatMessage } = useIntl()
  const [formSubmitted, setFormSubmitted] = useState(false)

  const onFormSubmit = (values) => {
    fetch('https://api.mentorly.co/forms/request_demo', {
      method: 'post',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
      body: JSON.stringify({
        locale,
        form_data: values,
        hutk: Cookies.get('hubspotutk'),
      }),
    })
      .then((response) => response.json())
      .then(() => {
        setFormSubmitted(true)
        if (onSubmit) {
          onSubmit()
        }
        Router.push('/[locale]/thank-you', `/${locale}/thank-you`)
      })
      .catch((error) => {
        setFormSubmitted(true)
        console.error('Request failed', error)
      })
  }

  if (formSubmitted) {
    return <div>Done!</div>
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onFormSubmit}
    >
      {({ isSubmitting, handleSubmit }) => {
        return (
          <div>
            <form id={`${id}-${locale}`} onSubmit={handleSubmit}>
              <div className="box">
                <Field compact name="firstname" />
                <Field compact name="lastname" />
                <Field compact name="email" type="email" />
                <Field compact name="phone" />
                <Field compact name="company" />
                <Field compact name="jobtitle" />
                <Field
                  compact
                  name="company_size"
                  control={Select}
                  options={['1-100', '101-500', '500-1000', '1000+']}
                />
                <div className="mt-6">
                  <Button type="submit" disabled={isSubmitting} full>
                    {formatMessage({ id: 'button.sendRequest' })}
                  </Button>
                </div>
              </div>
            </form>
          </div>
        )
      }}
    </Formik>
  )
}

export default RequestDemoForm
