import classNames from 'classnames'
import { useIntl } from 'react-intl'

import styles from './content.module.scss'
import Image from './Image'

const Content = ({ group }) => {
  const { aboutTitle, aboutSubtitle, aboutText } = group
  const { formatMessage } = useIntl()

  return (
    <section className="my-0 wrapper pb-8">
      <div className="mx-auto container flex flex-col">
        <div id="program-info" className="w-auto flex-grow mb-10 pr-0">
          <div
            className="inline-block mb-8"
            style={{
              borderBottom: `6px solid ${
                group.styles.backgroundColor || '#fdde35'
              }`,
            }}
          >
            <div className="text-sm font-bold tracking-widest uppercase">
              {aboutTitle || formatMessage({ id: 'group.about' })}
            </div>
          </div>
          {aboutSubtitle && (
            <div className="text-3xl font-bold mb-2">{aboutSubtitle}</div>
          )}
          <div
            className="rich-text"
            dangerouslySetInnerHTML={{ __html: aboutText }}
          />
        </div>
        {group.partnerLogoImages.length > 0 && (
          <aside className="w-auto my-0 pl-0 mb-4 text-left">
            <div className="text-sm font-bold mb-4">
              {group.partnerHeader ||
                formatMessage({ id: 'group.ourPartners' })}
            </div>
            <div className="items-center flex-wrap justify-start">
              {group.partnerLogoImages.map((logoImage) =>
                logoImage.url ? (
                  <a
                    href={logoImage.url}
                    key={logoImage.id}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <PartnerImage details={logoImage} />
                  </a>
                ) : (
                  <PartnerImage key={logoImage.id} details={logoImage} />
                )
              )}
            </div>
          </aside>
        )}
      </div>
    </section>
  )
}

const PartnerImage = ({ details }) => (
  <Image
    className={classNames('mb-3 my-0 mr-8 inline-block', styles.partnerLogos)}
    src={details.imageUrl}
    alt=""
  />
)

export default Content
