const Image = ({ alt, href, ...props }) => {
  let imageTag = <img src={props.src} alt={alt} {...props} />

  if (href) {
    return (
      <a href={href} target="_blank" rel="noreferrer">
        {imageTag}
      </a>
    )
  }

  return imageTag
}

export default Image
