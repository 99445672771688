import { RequestDemo } from 'components/Forms/RequestDemo/'
import { H2 } from 'components/Headings'
import Query from 'components/PageContent/Query'
import { Panel } from 'components/Panel'

export const Stats = () => {
  return (
    <Query id="b2b.home.stats">
      {({ pageContent }) => (
        <Panel color="yellow" hasWave className="pt-16">
          <Panel.Container flex>
            <div className="md:w-1/2">
              <H2>{pageContent.title}</H2>
              <div
                className="mb-10 rich-text stats"
                dangerouslySetInnerHTML={{ __html: pageContent.body }}
              />
              <RequestDemo formId="request-demo" className="py-3 text-xl" />
            </div>
            <div className="w-1/2 mt-16 mx-auto">
              <img src="/images/04.png" className="max-h-panel" alt="" />
            </div>
          </Panel.Container>
        </Panel>
      )}
    </Query>
  )
}
