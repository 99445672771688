import { RequestDemo } from 'components/Forms/RequestDemo/'
import { H2 } from 'components/Headings'
import Query from 'components/PageContent/Query'
import { Panel } from 'components/Panel'

export const Hero = () => {
  return (
    <Query id="b2b.home.intro">
      {({ pageContent }) => (
        <Panel color="yellow" className="overflow-hidden -mt-48 pt-48 md:pt-64">
          <Panel.Container>
            <div className="relative lg:w-1/2 mt-12 md:mt-0 mb-32 lg:mb-6 xl:mb-16 z-10">
              <H2>{pageContent.title}</H2>
              <div
                className="mb-10 md:w-1/2 lg:w-2/3 text-lg"
                dangerouslySetInnerHTML={{ __html: pageContent.body }}
              />
              <RequestDemo formId="request-demo" className="py-3 text-xl" />
            </div>
          </Panel.Container>
          <div className="w-3/4 sm:w-7/12 md:w-1/2 lg:w-7/12 xl:w-1/2 absolute right-0 bottom-0 z-0 -mb-16 max-w-2xl">
            <img
              src="/images/01.png"
              className="absolute bottom-0 right-0"
              alt=""
            />
          </div>
        </Panel>
      )}
    </Query>
  )
}
