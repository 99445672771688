import { Image } from 'components/Image'
import { VerticalWave } from 'components/Wave/VerticalWave'
import { Wave } from 'components/Wave/Wave'
import { useEffect, useState } from 'react'

// @TODO: replace slideshow elements with /Components/display/SlideShow
const TitleImage = ({ group }) => {
  const [currentId, setCurrentId] = useState(0)
  const images = group.backgroundImages.map((i) => i.imageUrl)

  useEffect(() => {
    if (images.length < 2) {
      return undefined
    }

    const interval = setInterval(() => {
      setCurrentId((oldId) => (oldId === images.length - 1 ? 0 : oldId + 1))
    }, 5000)
    return () => clearInterval(interval)
  }, images)

  return (
    <div className="w-full h-full absolute">
      {images.map((image, i) => (
        <Image
          key={image}
          className={`absolute duration-1000 ${
            i === currentId ? 'opacity-100' : 'opacity-0'
          }`}
          alt=""
          src={image}
        />
      ))}
      <div className="hidden md:block w-10 h-full absolute top-0 left-0">
        <VerticalWave color="white" />
      </div>
      <div className="block md:hidden w-full h-full absolute bottom-0 right-0">
        <Wave color="white" />
      </div>
    </div>
  )
}

export default TitleImage
