import * as Yup from 'yup'

export const validationSchema = Yup.object({
  company: Yup.string().required('* Required'),
  company_size: Yup.string().required('* Required'),
  email: Yup.string().required('* Required'),
  firstname: Yup.string().required('* Required'),
  jobtitle: Yup.string().notRequired('* Required'),
  lastname: Yup.string().required('* Required'),
  phone: Yup.string().required('* Required'),
})

export const initialValues = {
  company: '',
  company_size: '',
  email: '',
  firstname: '',
  jobtitle: '',
  lastname: '',
  phone: '',
}
