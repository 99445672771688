import { useQuery } from '@apollo/client'
import { Card } from 'components/Card'
import { RequestDemo } from 'components/Forms/RequestDemo/'
import { H2 } from 'components/Headings'
import Query from 'components/PageContent/Query'
import { Panel } from 'components/Panel'
import { Video } from 'components/Video'
import { benefitContentsQuery } from 'lib/queries'
import React from 'react'
import { useIntl } from 'react-intl'

const Benefits = () => {
  const intl = useIntl()
  const { loading, error, data } = useQuery(benefitContentsQuery, {
    variables: { locale: intl.locale },
  })

  if (loading) {
    return null
  }

  if (error) {
    console.error(error)
    return null
  }

  const { benefitContents } = data
  return (
    <div className="lg:flex flex-wrap justify-start mb-32">
      {benefitContents.map((item) => (
        <Card key={item.id} image={item.imageUrl} title={item.title}>
          <div dangerouslySetInnerHTML={{ __html: item.body }} />
        </Card>
      ))}
    </div>
  )
}

export const VideoPanel = () => {
  return (
    <Query id="b2b.home.benefits">
      {({ pageContent }) => (
        <Panel color="white" hasWave className="pt-16">
          <Panel.Container>
            <div>
              <H2>{pageContent.title}</H2>
              <div
                className="mb-10"
                dangerouslySetInnerHTML={{ __html: pageContent.body }}
              />
              <div className="my-16">
                <Video />
              </div>
            </div>
            <Benefits />
            <div className="text-center">
              <RequestDemo
                formId="request-demo"
                className="py-3 text-xl mx-auto"
              />
            </div>
          </Panel.Container>
        </Panel>
      )}
    </Query>
  )
}
