import classNames from 'classnames'
import React from 'react'

import { Wave } from '../Wave/Wave'

const Container = ({ children, flex, className }) => (
  <div
    className={classNames(
      'container mx-auto',
      flex && 'md:flex justify-between',
      className
    )}
  >
    {children}
  </div>
)

const Panel = ({ color = 'white', className, children, hasWave, style }) => {
  const textColor = ['blue', 'purple'].includes(color) ? 'white' : 'black'

  return (
    <section
      className={classNames(
        'relative flex flex-col lg:justify-center wrapper pt-6 pb-32',
        color && `bg-${color}`,
        `text-${textColor}`,
        className
      )}
      style={style}
    >
      {hasWave && (
        <div className="absolute top-0 left-0 right-0 h-24 -mt-24">
          <Wave color={`text-${color}`} />
        </div>
      )}
      {children}
    </section>
  )
}

Panel.Container = Container
export default Panel
