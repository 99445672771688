import Group from 'components/Graphql/Group'
import Content from 'components/pages/Groups/Content'
import Hero from 'components/pages/Groups/Hero'
import Layout from 'components/pages/Groups/Layout'
import Members from 'components/pages/Groups/Members'
import { groupHomeQuery } from 'lib/queries'

const HomePage = ({ groupId }) => {
  // fontFamily is set in Layout too but only for whiteLabel
  // for greyLabel, it's just this landing page
  return (
    <Group query={groupHomeQuery} groupId={groupId}>
      {({ group }) => (
        <main
          className="h-full w-full"
          style={group ? { fontFamily: group.styles.fontName } : null}
        >
          <Hero group={group} />
          <Content group={group} />
          <Members group={group} type="mentor" frontPage />
        </main>
      )}
    </Group>
  )
}

HomePage.Layout = Layout

export default HomePage
