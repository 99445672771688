import { ButtonLink } from 'components/Button'
import { H2 } from 'components/Headings'
import Query from 'components/PageContent/Query'
import { Panel } from 'components/Panel'
import Link from 'next/link'
import { useIntl } from 'react-intl'

const Tools = () => {
  const { locale, formatMessage } = useIntl()

  return (
    <Query id="b2b.home.tools">
      {({ pageContent }) => (
        <Panel.Container flex>
          <div className="max-w-md relative z-10 pb-8">
            <H2>{pageContent.title}</H2>
            <div
              className="mb-10 rich-text feature-list"
              dangerouslySetInnerHTML={{ __html: pageContent.body }}
            />
            <Link href={`/${locale}/case-studies/`} passHref>
              <ButtonLink>
                {formatMessage({ id: 'button.learnMore' })}
              </ButtonLink>
            </Link>
          </div>
          <div className="hidden md:block md:w-1/3 lg:w-2/5 pt-1/12">
            <img alt="" src="/images/drone.png" className="max-h-panel" />
          </div>
        </Panel.Container>
      )}
    </Query>
  )
}

const Cases = () => {
  const { locale, formatMessage } = useIntl()

  return (
    <Query id="b2b.home.cases">
      {({ pageContent }) => (
        <Panel.Container flex>
          <div className="absolute bottom-0 -mb-24 md:-mb-36 xl:-mb-48 right-0 left-0 z-0">
            <img src="/images/plante.png" className="w-full" alt="" />
          </div>
          <div className="absolute w-1/3 -mt-32 -mb-8 lg:mb-0 bottom-0 right-0 md:right-auto">
            <img
              src="/images/suitman.png"
              className="max-h-panel w-full max-w-xs"
              alt=""
            />
          </div>

          <div className="mt-8 md:mt-0 lg:mb-18 max-w-md self-center z-10 md:ml-1/2">
            <H2>{pageContent.title}</H2>
            <div
              className="mb-10 rich-text feature-list"
              dangerouslySetInnerHTML={{ __html: pageContent.body }}
            />
            <Link href={`/${locale}/case-studies/`} passHref>
              <ButtonLink>
                {formatMessage({ id: 'button.viewUseCases' })}
              </ButtonLink>
            </Link>
          </div>
        </Panel.Container>
      )}
    </Query>
  )
}

export const Features = () => {
  return (
    <Panel color="purple" className="pt-24 text-white overflow-hidden">
      <Tools />
      <Cases />
    </Panel>
  )
}
