import { useIntl } from 'react-intl'

import styles from './Video.module.scss'

const regexp = /vimeo\.com\/(.*)/

const VIDEOS = {
  b2b: {
    en: '421330942',
    fr: '422534296',
  },
  b2c: {
    en: '463160850',
    fr: '552948916',
  },
}

const embedUrl = (url, fallbackId) => {
  const matches = url && url.match(regexp)
  const id = matches ? matches[1] : fallbackId

  return `https://player.vimeo.com/video/${id}`
}

const Video = ({ url, category = 'b2b' }) => {
  const { locale } = useIntl()

  const playerUrl = embedUrl(url, VIDEOS[category][locale])

  return (
    <div className={styles.aspect}>
      <iframe
        title="Video player"
        src={playerUrl}
        frameBorder="0"
        allow="autoplay; fullscreen"
        allowFullScreen
      ></iframe>
    </div>
  )
}

export default Video
