import { useQuery } from '@apollo/client'
import Alert from 'components/feedback/Alert'
import { pageContentQuery } from 'lib/queries'
import { useIntl } from 'react-intl'

const Query = ({ children, id }) => {
  const { locale } = useIntl()

  const { loading, error, data } = useQuery(pageContentQuery, {
    variables: { id, locale },
  })

  if (loading) {
    return null
  }

  if (error) {
    return (
      <Alert title="Error" description={error.message} type="error" showIcon />
    )
  }

  if (!data.pageContent) {
    return (
      <Alert showIcon type="warning">
        Content for <code>{id}</code> missing
      </Alert>
    )
  }

  return children(data)
}

export default Query
